import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { continueRule, ContinueState } from "../continue/ContinueSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import {
  BodyTextRocket,
  ErrorCrossCircleNew,
  ErrorCrossCircleRocket,
  Heading1New,
  Heading1Rocket,
  ThemedButtonNew,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import { auth0Logout } from "../../common/utils/navigation";
import { RootState } from "../../store/Store";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { RouteState } from "../route/RouteSlice";
import { dataLayerFlybuysLinkingFailedPage } from "../../tracking/tracking";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";
import { rdsMargin } from "@coles/rocket";

const FlybuysLinkingFailedPage = () => {
  const dispatch = useDispatch();

  const { auth0Config }: AppConfigState = useSelector(
    (state: RootState) => state.appConfig
  );

  const { auth0State }: RouteState = useSelector(
    (state: RootState) => state.route
  );

  const { continueToken }: ContinueState = useSelector(
    (state: RootState) => state.continue
  );

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerFlybuysLinkingFailedPage(clientName);
    }
  }, [clientName]);

  useEffect(() => {
    dispatch(setShowLoader(false));
  }, []);

  function continueOnClickButton(e: React.MouseEvent) {
    e.preventDefault();
    if (!auth0State || !continueToken) {
      auth0Logout(auth0Config.auth0LogoutEndPoint);
      return;
    }

    dispatch(continueRule());
  }

  const isRocketEnabled = useRocketEnabled();

  const crossCircle = isRocketEnabled ? (
    <ErrorCrossCircleRocket
      data-testid="error-icon-rocket"
      role="presentation"
    />
  ) : (
    <ErrorCrossCircleNew data-testid="error-icon" role="presentation" />
  );

  const header = isRocketEnabled ? (
    <HeaderRocket>Something went wrong</HeaderRocket>
  ) : (
    <Header>Something went wrong</Header>
  );

  const upperDescription = isRocketEnabled ? (
    <DescriptionRocket>
      We're unable to link your flybuys account right now.
    </DescriptionRocket>
  ) : (
    <Description>
      We're unable to link your flybuys account right now.
    </Description>
  );

  const lowerDescription = isRocketEnabled ? (
    <DescriptionRocket>
      You can link your flybuys account from within your Coles account,
      otherwise we will remind you when you sign in next time.
    </DescriptionRocket>
  ) : (
    <Description>
      You can link your flybuys account from within your Coles account,
      otherwise we will remind you when you sign in next time.
    </Description>
  );

  const themedButton = isRocketEnabled ? (
    <ThemedButtonRocket
      isFullWidth
      label="Continue"
      data-testid="continue-button-rocket"
      className="sentry-unmask"
      onClick={(e) => {
        continueOnClickButton(e);
      }}
    />
  ) : (
    <ThemedButtonNew
      data-testid="continue-button"
      isFullWidth={true}
      onClick={(e) => {
        continueOnClickButton(e);
      }}
    >
      Continue
    </ThemedButtonNew>
  );

  return (
    <Container>
      {crossCircle}
      {header}
      {upperDescription}
      {lowerDescription}
      {themedButton}
    </Container>
  );
};

export default FlybuysLinkingFailedPage;

const Container = styled.div`
  text-align: center;
`;

const Header = styled(Heading1New)`
  margin: 28px 0 16px 0;
`;

const HeaderRocket = styled(Heading1Rocket)`
  ${rdsMargin.s6.top};
  ${rdsMargin.s4.bottom};
`;

const Description = styled.p`
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

const DescriptionRocket = styled(BodyTextRocket).attrs<{
  className: string;
  children: React.ReactNode;
}>({
  variant: "body_300",
})`
  ${rdsMargin.none.top};
  ${rdsMargin.s7.bottom};
`;
