import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Heading1New,
  Heading1Rocket,
  ThemedButtonNew,
  ThemedButtonRocket,
  ErrorCrossCircleNew,
  ErrorCrossCircleRocket,
  BodyTextRocket,
} from "ccp-common-ui-components";
import { useDispatch, useSelector } from "react-redux";
import { verifyLater } from "../verify-later/VerifyLaterSlice";
import { RootState } from "../../store/Store";
import { getErrorMessage } from "../application-error/ApplicationErrorUtils";
import { dataLayerVerifyMobileErrorPage } from "../../tracking/tracking";
import { useWithNav } from "../../utils/withNav";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";
import { rdsMargin } from "@coles/rocket";

const VerifyMobileErrorPage = function () {
  const dispatch = useDispatch();
  const withNav = useWithNav();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerVerifyMobileErrorPage(clientName);
    }
  }, [clientName]);

  const { errorCode } = useSelector(
    (state: RootState) => state.applicationError
  );

  console.log("errorCode", errorCode);
  const errorMessage = getErrorMessage(errorCode);

  const isRocketEnabled = useRocketEnabled();

  const header = isRocketEnabled ? (
    <Heading1Rocket>Cannot verify</Heading1Rocket>
  ) : (
    <Heading1New>Cannot verify</Heading1New>
  );

  const crossCircle = isRocketEnabled ? (
    <ErrorCrossCircleRocket role="presentation" />
  ) : (
    <ErrorCrossCircleNew role="presentation" />
  );

  const message = isRocketEnabled ? (
    <MessageRocket data-testid="error-message-rocket">
      {errorMessage}
    </MessageRocket>
  ) : (
    <Message data-testid="error-message">{errorMessage}</Message>
  );

  const themedButton = isRocketEnabled ? (
    <ThemedButtonRocket
      isFullWidth
      label="Verify later"
      data-testid="verify-later-button-rocket"
      className="sentry-unmask"
      onClick={(e) => {
        e.preventDefault();
        dispatch(verifyLater(withNav()));
      }}
    />
  ) : (
    <StyledButton
      data-testid="verify-later-button"
      onClick={(e) => {
        e.preventDefault();
        dispatch(verifyLater(withNav()));
      }}
    >
      Verify later
    </StyledButton>
  );

  return (
    <MainDiv>
      {crossCircle}
      {header}
      {message}
      {themedButton}
    </MainDiv>
  );
};

export default VerifyMobileErrorPage;

const MainDiv = styled.div`
  margin: 40px 10px;
  text-align: center;
`;

const Message = styled.p`
  margin-bottom: 40px;
`;

const MessageRocket = styled(BodyTextRocket).attrs<{
  className: string;
  children: React.ReactNode;
}>({
  variant: "body_300",
})`
  ${rdsMargin.s7.bottom};
`;

const StyledButton = styled(ThemedButtonNew)`
  width: 100%;
`;
