import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Heading1New,
  Heading1Rocket,
  ThemedButtonNew,
  ThemedButtonRocket,
  ErrorCrossCircleNew,
  ErrorCrossCircleRocket,
} from "ccp-common-ui-components";
import styled from "styled-components";
import { continueRule } from "../continue/ContinueSlice";
import { RootState } from "../../store/Store";
import {
  getErrorMessage,
  getErrorTitle,
} from "../application-error/ApplicationErrorUtils";
import { dataLayerGenericErrorPage } from "../../tracking/tracking";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";

const GenericErrorPage = function () {
  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerGenericErrorPage(clientName);
    }
  }, [clientName]);

  const { errorCode } = useSelector(
    (state: RootState) => state.applicationError
  );

  const errorMessage = getErrorMessage(errorCode);
  const title = getErrorTitle(errorCode);

  const header = isRocketEnabled ? (
    <Heading1Rocket>{title}</Heading1Rocket>
  ) : (
    <Heading1New>{title}</Heading1New>
  );

  const crossCircle = isRocketEnabled ? (
    <ErrorCrossCircleRocket role="presentation" />
  ) : (
    <ErrorCrossCircleNew role="presentation" />
  );

  const themedButton = isRocketEnabled ? (
    <ThemedButtonRocket
      isFullWidth
      label="Back to login screen"
      data-testid="back-button-rocket"
      className="sentry-unmask"
      onClick={() => {
        dispatch(continueRule());
      }}
    />
  ) : (
    <StyledButton
      data-testid="back-button"
      onClick={() => {
        dispatch(continueRule());
      }}
    >
      Back to login screen
    </StyledButton>
  );

  return (
    <MainDiv>
      {crossCircle}
      {header}
      <Message>{errorMessage}</Message>
      {themedButton}
    </MainDiv>
  );
};

const MainDiv = styled.div`
  margin: 40px 10px;
  text-align: center;
`;

const Message = styled.p`
  margin-bottom: 40px;
`;

const StyledButton = styled(ThemedButtonNew)`
  width: 100%;
`;

export default GenericErrorPage;
