import React from "react";
import styled from "styled-components";
import {
  BodyTextRocket,
  ErrorCrossCircleNew,
  ErrorCrossCircleRocket,
  Heading1New,
  Heading1Rocket,
  Link,
  LinkRocket,
  ThemedButtonNew,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";
import { rdsMargin } from "@coles/rocket";

const MainDiv = styled.div`
  margin: 40px 10px;
  text-align: center;
`;

const StyledHeading1New = styled(Heading1New)`
  margin-bottom: 8px;
`;

const StyledHeading1Rocket = styled(Heading1Rocket)`
  ${rdsMargin.s2.bottom};
`;

const Message = styled.p`
  margin-top: 0px;
  margin-bottom: 24px;
`;

const MessageRocket = styled(BodyTextRocket).attrs<{
  className: string;
  children: React.ReactNode;
}>({
  variant: "body_300",
})`
  ${rdsMargin.none.top};
  ${rdsMargin.s6.bottom};
`;

const ContactColesLink = styled(Link)`
  font-size: 18px;
`;

export default function InvalidServerReponseErrorPage() {
  const originLoginUrl = useSelector(
    (state: RootState) => state.appConfig.originLoginUrl
  );

  const isRocketEnabled = useRocketEnabled();

  const header = isRocketEnabled ? (
    <StyledHeading1Rocket>Something went wrong</StyledHeading1Rocket>
  ) : (
    <StyledHeading1New>Something went wrong</StyledHeading1New>
  );

  const crossCircle = isRocketEnabled ? (
    <ErrorCrossCircleRocket role="presentation" />
  ) : (
    <ErrorCrossCircleNew role="presentation" />
  );

  const contactColesLink = isRocketEnabled ? (
    <LinkRocket
      label=" contact Customer Care"
      variant="primary"
      href="https://www.coles.com.au/help/contact#call"
      target="_blank"
      size="large"
    />
  ) : (
    <ContactColesLink
      primary={true}
      href="https://www.coles.com.au/help/contact#call"
      target="_blank"
    >
      {" "}
      contact Customer Care
    </ContactColesLink>
  );

  const message = isRocketEnabled ? (
    <MessageRocket data-testid="error-message-rocket">
      We can't log you in. Please try again, and if that doesn't work{" "}
      {contactColesLink}.
    </MessageRocket>
  ) : (
    <Message data-testid="error-message">
      We can't log you in. Please try again, and if that doesn't work{" "}
      {contactColesLink}.
    </Message>
  );

  const themedButton = isRocketEnabled ? (
    <ThemedButtonRocket
      isFullWidth
      label="Retry log in"
      data-testid="back-button-rocket"
      className="sentry-unmask"
      onClick={() => {
        window.location.replace(originLoginUrl);
      }}
    />
  ) : (
    <ThemedButtonNew
      isFullWidth={true}
      data-testid="back-button"
      onClick={() => {
        window.location.replace(originLoginUrl);
      }}
    >
      Retry log in
    </ThemedButtonNew>
  );

  return (
    <MainDiv>
      {crossCircle}
      {header}
      {message}
      {themedButton}
    </MainDiv>
  );
}
