import {
  Heading1New,
  Heading1Rocket,
  LinkRocket,
  SuccessTickCircleRedRocket,
  ThemedButtonNew,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirectToUrl } from "../../common/utils/navigation";
import { RootState } from "../../store/Store";
import {
  dataLayerFlybuysLinkPage,
  dataLayerFlybuysLinkPageNoThanks,
} from "../../tracking/tracking";
import {
  StyledIcon,
  StyledP,
  StyledDescription,
  Description,
  StyledSpan,
  StyledCenteredDiv,
  LinkContainer,
  NoThanksLink,
  SmallerDescription,
  StyledLink,
  BoldText,
  FlybuysPrivacyUrl,
} from "./LinkFlybuysPageStyles";
import { flybuysPreferencesAction } from "./FlybuysAction";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";
import styled from "styled-components";
import { rdsMargin } from "@coles/rocket";

const StyledLinkRocket = styled(LinkRocket)`
  display: inline-block;
`;

const StyledSecondLinkRocket = styled(ThemedButtonRocket)`
  ${rdsMargin.s3.bottom};
  display: block;
`;

function LinkFlybuysPage() {
  const { linkFlybuysUrl, registerFlybuysUrl } = useSelector(
    (state: RootState) => state.linkFlybuys
  );
  const isRocketEnabled = useRocketEnabled();

  const textPoints = [
    "Collect 1 point per $1 you spend in store or online.",
    "View items you've bought before in-store and online to make shopping easier.",
    "Redeem 2,000 points for $10 off when you use Flybuys dollars.",
  ];

  const dispatch = useDispatch();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerFlybuysLinkPage(clientName);
    }
  }, [clientName]);

  const header = isRocketEnabled ? (
    <Heading1Rocket className="sentry-unmask">
      Link your Flybuys card
    </Heading1Rocket>
  ) : (
    <Heading1New>Link your Flybuys card</Heading1New>
  );

  const linkMyFlybuysButton = isRocketEnabled ? (
    <ThemedButtonRocket
      data-testid="link-flybuys-rocket"
      isFullWidth={true}
      label="Link my flybuys"
      onClick={() => {
        redirectToUrl(linkFlybuysUrl);
      }}
      className="sentry-unmask"
    />
  ) : (
    <ThemedButtonNew
      data-testid="link-flybuys"
      isFullWidth={true}
      onClick={() => {
        redirectToUrl(linkFlybuysUrl);
      }}
    >
      Link my flybuys
    </ThemedButtonNew>
  );

  const redCircleTick = isRocketEnabled ? (
    <SuccessTickCircleRedRocket size={20} />
  ) : (
    <StyledIcon size={20} />
  );

  const privacyPolicyLink = isRocketEnabled ? (
    <StyledLinkRocket
      label="Flybuys privacy policy"
      data-testid="flybuys-privacy-policy-rocket"
      variant="primary"
      href={FlybuysPrivacyUrl}
      target="_blank"
      size="small"
      className="fs-unmask"
    />
  ) : (
    <StyledLink href={FlybuysPrivacyUrl} target="_blank">
      Flybuys privacy policy
    </StyledLink>
  );

  const dontAskAgainLink = isRocketEnabled ? (
    <StyledSecondLinkRocket
      data-testid="no-thanks-link-rocket"
      label="Don’t ask me again"
      variant="link"
      size="medium"
      href=""
      className="fs-unmask"
      onClick={(e) => {
        e.preventDefault();

        dispatch(flybuysPreferencesAction({ skipFlybuys: true }));

        dataLayerFlybuysLinkPageNoThanks();
      }}
    />
  ) : (
    <NoThanksLink
      data-testid="no-thanks-link"
      onClick={(e) => {
        e.preventDefault();

        dispatch(flybuysPreferencesAction({ skipFlybuys: true }));

        dataLayerFlybuysLinkPageNoThanks();
      }}
    >
      Don’t ask me again
    </NoThanksLink>
  );

  const registerFlybuysLink = isRocketEnabled ? (
    <LinkRocket
      label="Register a Flybuys account"
      data-testid="register-flybuys-account-rocket"
      href={registerFlybuysUrl}
      size="small"
      className="fs-unmask"
      variant="primary"
    />
  ) : (
    <StyledLink href={registerFlybuysUrl}>
      Register a Flybuys account
    </StyledLink>
  );

  return (
    <>
      {header}
      <StyledDescription>
        Whether it's a quick shop for essentials or stocking up the pantry,
        you'll collect points on every dollar you spend at Coles.
      </StyledDescription>

      <StyledP>
        {textPoints.map((textItem) => (
          <Description key={textItem}>
            {redCircleTick}
            <StyledSpan>{textItem}</StyledSpan>
          </Description>
        ))}
      </StyledP>

      <SmallerDescription>
        Once linked, you will also be able to collect Flybuys points on in-store
        purchases at Coles Supermarkets by scanning your Coles barcode.
      </SmallerDescription>
      <SmallerDescription>
        By scanning your Coles barcode, Flybuys will also share your email
        address and personal information with Coles in accordance with your
        email share settings and the {privacyPolicyLink}.
      </SmallerDescription>

      <StyledCenteredDiv>{linkMyFlybuysButton}</StyledCenteredDiv>

      <LinkContainer>{dontAskAgainLink}</LinkContainer>

      <SmallerDescription>
        <BoldText>Not a member? </BoldText>
        {registerFlybuysLink} and start collecting points on your next shop.
      </SmallerDescription>
    </>
  );
}

export default LinkFlybuysPage;
